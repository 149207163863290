// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1g2_9{padding-left:20px;padding-right:20px}._3n8Tt{margin:0 auto;padding:100px 0 40px;max-width:1000px}", ""]);
// Exports
exports.locals = {
	"root": "_1g2_9",
	"container": "_3n8Tt"
};
module.exports = exports;
